@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
#header {
  position: fixed;
  width: 230px;
  top: 0px;
  z-index: 2;
  transition: width 0.1s;
}
.header-collapse {
  width: 80px !important;
  transition: width 0.1s;
}
#header .pro-sidebar {
  height: 100vh;
  z-index: 1;
}
#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar .pro-sidebar-header .App-logo {
  width: 120px;
}
#header .pro-sidebar.collapsed .pro-sidebar-header .App-user {
  width: 55px;
  height: 50px;
  padding-top: 5px;
  margin-top: 35px;
}
#header .pro-sidebar .pro-sidebar-header .App-user {
  border: 5px solid #B9103D;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #ffffff;
  margin: 0 auto;
}
#header .pro-sidebar.collapsed .pro-sidebar-header .App-logo {
  width: 80px;
}
#header .pro-sidebar.collapsed .pro-sidebar-header .logotext p {
  display: none;
}
#header .pro-sidebar .pro-sidebar-header .logotext {
  padding-top: 15px;
  height: 61px;
  position: relative;
}
#header .pro-sidebar-inner {
  background-color: #B9103D;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 18px;
  padding: 0 20px;
  color: #B9103D;
  text-align: center;
  padding-bottom: 5px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow-wrap: break-word;
  line-height: 19px;
  height: 30px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 0px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #FFFFFF;
  margin: 5px 0px;
}
#header .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  background: #FFFFFF;
  text-align: center;
  padding-top: 30px;
  height: 200px;
}
#header .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background: none;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-icon i {
  background-color: #B9103D;
  color: #FFFFFF !important;
  border-radius: 3px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .active .pro-icon-wrapper .pro-icon i {
  background-color: #FFFFFF;
  color: #B9103D !important;
  border-radius: 3px;
}
/* #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: red;
} */
#header .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  animation: 0.5s 1;
  transition: transform .2s;
  transform: scale(1.2);
}
#header .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
  animation: 0.5s 1;
  transition: transform .2s;
  transform: scale(1.2);
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 100%);
}
#header .logo {
  padding: 20px;
}
#header .pro-sidebar .pro-menu .active a {
  color: #B9103D;
}
#header .pro-sidebar .pro-menu a {
  color: #FFFFFF;
}
/* #header .pro-sidebar .expandedArrow {
  float: left;
} */
#header .pro-sidebar .collapsedArrow {
  padding-left: 77px;
}
#header .pro-sidebar .collapsedArrow .pro-inner-item{
  padding-top: 8px;
}
#header .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  padding-top: 50px;
}
.tooltip-menu-wrapper {
  position: absolute;
  display: none;
  bottom: 100%;
  right: 260px;
  width: 0;
  top: -40%;
  right: 96%;
}
.tooltiptext {
  display: block;
  position: relative;
  right: 100%;
  width: 200px;
  color: #FFFFFF;
  background: #555;
  text-align: center;
  border-radius: 8px;
  z-index: 1;
  word-wrap: break-word;
  white-space: normal;
  padding: 6px;
}
.set-icon-position {
  padding-left: 77px !important;
}
@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
